import React, { useEffect } from "react";
import "./matka.scoped.css";
import Slider from "react-slick";
import moment from "moment";
import { getMatkaMarketAPI } from "../../service/matka";
export default function MatkaDashboard() {
  const [matkaGames, setMatkaGames] = React.useState([]);
  const getGames = async () => {
    const { response } = await getMatkaMarketAPI();
    setMatkaGames(response);
  };
  useEffect(() => {
    getGames();
  }, []);
  return (
    <div className="dashboard">
      <div className="introwatch">
        <p>Coming Soon ....</p>
        <p>🙏🏼 2EXCH Matka 🙏🏼</p>
        <div className="watch">
          <p>
            <time dateTime={1719495897220}>
              {moment().format("DD-MM-YYYY")}
            </time>
          </p>
          <time dateTime={1719495928225}>{moment().format("hh:mm A")}</time>
        </div>
      </div>
      <div
        style={{
          overflowY: "hidden",
        }}
        className="carousel-root result-slider"
      >
        <Slider>
          <li className="slide">
            <div className="silders">
              <p className="header">Disawar</p>
              <p className="content">Result - 40</p>
              <p className="content">27-06-2024 06:00 AM</p>
            </div>
          </li>

          <li className="slide">
            <div className="silders">
              <p className="header">Gali</p>
              <p className="content">Result - 72</p>
              <p className="content">26-06-2024 11:59 PM</p>
            </div>
          </li>

          <li className="slide">
            <div className="silders">
              <p className="header">Faridabaad</p>
              <p className="content">Result - 88</p>
              <p className="content">26-06-2024 06:00 PM</p>
            </div>
          </li>
        </Slider>
      </div>
      <div
        style={{
          position: "static",
        }}
        className="row"
      >
        {matkaGames?.map((game) => (
          <div
            onClick={() => {
              window.location.href = "/matka/" + game?.id;
            }}
            className="col-md-3 col-sm-12"
          >
            <div className="matka-round ">
              <div className="details">
                <p className="heading">{game?.matka?.name}</p>
                <p className="subline">
                  {game?.matka?.openTime} To {game?.matka?.openTime} Result at{" "}
                  {game?.matka?.resultTime}
                </p>
              </div>
              <div>
                <p className="number">--</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
